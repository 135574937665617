export default [
  {
    path: '/',
    component: () => import('@/views/gestao/App.vue'),
    meta: {
      auth: true,
    },
    children: [
      // {
      //   path: '',
      //   name: 'dashboard',
      //   component: () => import('@/views/gestao/dashboard/GestaoDashboard.vue'),
      // },
      {
        path: '',
        name: 'servidores',
        meta: {
          gestaoEntidade: true,
        },
        component: () => import('@/views/gestao/servidor/ServidorIndex.vue'),
      },
      {
        path: 'servidores/:id',
        component: () => import('@/views/gestao/servidor/ServidorDetalhe.vue'),
        meta: {
          gestaoEntidade: true,
        },
        children: [
          {
            path: '',
            component: () => import('@/views/gestao/servidor/ServidorMenu.vue'),
            children: [
              {
                path: '',
                name: 'servidores-margem',
                component: () =>
                  import('@/views/gestao/servidor/ServidorMargem.vue'),
              },
              {
                path: 'consignacoes',
                name: 'consignacoes',
                component: () =>
                  import('@/views/gestao/consignacoes/ConsignacaoList.vue'),
              },
              {
                path: 'renegociacoes',
                name: 'renegociacoes',
                component: () =>
                  import('@/views/gestao/renegociacao/RenegociacaoList.vue'),
              },
              {
                path: 'reserva_cartao',
                name: 'reserva_cartao',
                component: () =>
                  import('@/views/gestao/reservaCartao/ReservaCartaoList.vue'),
              },
              {
                path: 'historico_margem',
                name: 'historico_margem',
                component: () =>
                  import('@/views/gestao/servidor/historicoMargem.vue'),
              },
              {
                path: 'propostas',
                name: 'propostas',
                component: () =>
                  import('@/views/gestao/propostas/PropostaList.vue'),
              },
              {
                path: 'compra-dividas',
                name: 'compra-divida-lista',
                component: () =>
                  import('@/views/gestao/compraDivida/CompraDividaList.vue'),
              },
              {
                path: 'parametros',
                name: 'parametros',
                component: () =>
                  import('@/views/gestao/parametros/Parametros.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'entidades',
        name: 'entidades',
        component: () => import('@/views/gestao/entidade/EntidadeLista.vue'),
      },
      {
        path: 'entidades/:id/vinculos',
        name: 'entidade-vinculos',
        component: () => import('@/views/gestao/entidade/EntidadeVinculos.vue'),
      },
      {
        path: 'atividades',
        name: 'atividades',
        component: () => import('@/views/gestao/atividade/AtividadeIndex.vue'),
      },
      {
        path: 'atividades/nova',
        name: 'atividade-nova',
        component: () => import('@/views/gestao/atividade/AtividadeForm.vue'),
      },
      {
        path: 'atividades/:id',
        name: 'atividade-editar',
        component: () => import('@/views/gestao/atividade/AtividadeForm.vue'),
      },
      {
        path: 'orgaos',
        name: 'orgaos',
        component: () => import('@/views/gestao/orgao/OrgaoIndex.vue'),
      },
      {
        path: 'orgaos/new',
        name: 'orgao-new',
        component: () => import('@/views/gestao/orgao/OrgaoForm.vue'),
      },
      {
        path: 'orgaos/:id',
        name: 'orgao-edit',
        component: () => import('@/views/gestao/orgao/OrgaoForm.vue'),
      },
      {
        path: 'rubricas',
        name: 'rubricas',
        component: () => import('@/views/gestao/rubrica/RubricaIndex.vue'),
        meta: {
          gestaoEntidade: true,
        },
      },
      {
        path: 'rubricas/new',
        name: 'rubricas-new',
        component: () => import('@/views/gestao/rubrica/RubricaFormAll.vue'),
        meta: {
          gestaoEntidade: true,
        },
      },
      {
        path: 'rubricas/:id',
        name: 'rubricas-edit',
        component: () => import('@/views/gestao/rubrica/RubricaFormAll.vue'),
        meta: {
          gestaoEntidade: true,
        },
      },
      {
        path: 'consignatarias',
        name: 'consignatarias',
        component: () =>
          import('@/views/gestao/consignataria/ConsignatariaIndex.vue'),
        meta: {
          gestaoEntidade: true,
        },
      },
      {
        path: 'consignatarias/new',
        name: 'consignataria-new',
        component: () =>
          import('@/views/gestao/consignataria/ConsignatariaForm.vue'),
        meta: {
          gestaoEntidade: true,
        },
      },
      {
        path: 'consignatarias/:id',
        name: 'consignataria-edit',
        component: () =>
          import('@/views/gestao/consignataria/ConsignatariaForm.vue'),
        meta: {
          gestaoEntidade: true,
        },
      },
      {
        path: 'consignataria/:idConsignataria/rubricas',
        name: 'consignataria-rubricas',
        component: () => import('@/views/gestao/rubrica/RubricaIndex.vue'),
        meta: {
          gestaoEntidade: true,
        },
      },
      {
        path: 'consignataria/:idConsignataria/rubricas/new',
        name: 'consignataria-rubrica-nova',
        component: () => import('@/views/gestao/rubrica/RubricaFormAll.vue'),
        meta: {
          gestaoEntidade: true,
        },
      },
      {
        path: 'consignataria/:idConsignataria/rubricas/:id',
        name: 'consignataria-rubrica-editar',
        component: () => import('@/views/gestao/rubrica/RubricaFormAll.vue'),
        meta: {
          gestaoEntidade: true,
        },
      },
      {
        path: 'consignataria/:idConsignataria/usuarios',
        name: 'consignataria-usuarios',
        component: () =>
          import('@/views/gestao/usuario/UsuarioConsignatariaLista.vue'),
      },
      {
        path: 'consignataria/:idConsignataria/usuario/new',
        name: 'consignataria-usuario-nova',
        component: () =>
          import('@/views/gestao/usuario/UsuarioConsignatariaForm.vue'),
      },
      {
        path: 'consignataria/:idConsignataria/usuario/:id',
        name: 'consignataria-usuario-editar',
        component: () =>
          import('@/views/gestao/usuario/UsuarioConsignatariaForm.vue'),
      },
      {
        path: 'consignataria/:idConsignataria/contratos',
        component: () => import('@/views/gestao/contratos/ContratoMenu.vue'),
        children: [
          {
            path: '',
            name: 'consignataria-contratos-parcelas',
            component: () =>
              import('@/views/gestao/contratos/ContratoParcelasLista.vue'),
          },
        ],
      },
      {
        path: 'financas',
        name: 'financas',
        component: () => import('@/views/gestao/financa/FinancaOperacoes.vue'),
        meta: {
          gestaoEntidade: false,
          gestaoFinanceira: true,
        },
      },
      {
        path: 'tabela-precificacao',
        name: 'tabela-precificacao',
        component: () =>
          import('@/views/gestao/financa/TabelaPrecificacao.vue'),
        meta: {
          gestaoEntidade: false,
          gestaoFinanceira: true,
        },
      },
      {
        path: 'configuracoes',
        meta: {
          gestaoEntidade: false,
          gestaoFinanceira: false,
        },
        component: () =>
          import('@/views/gestao/configuracao/ConfiguracaoIndex.vue'),
        children: [
          {
            path: '',
            name: 'configuracoes',
            component: () =>
              import('@/views/gestao/configuracao/ConfiguracaoIndex.vue'),
          },
          {
            path: 'new',
            name: 'configuracao-new',
            component: () =>
              import('@/views/gestao/configuracao/ConfiguracaoForm.vue'),
          },
          {
            path: ':id',
            name: 'configuracao-edit',
            component: () =>
              import('@/views/gestao/configuracao/ConfiguracaoForm.vue'),
          },
        ],
      },

      {
        path: 'nova-mensagem',
        name: 'nova-mensagem',
        component: () => import('@/views/gestao/configuracao/MensagemForm.vue'), // Conferir este caminho
      },

      {
        path: 'ajuste-parametros',
        name: 'ajuste-parametros',
        component: () =>
          import('@/views/gestao/configuracao/AjusteParametros.vue'),
        meta: {
          gestaoEntidade: false,
        },
      },

      {
        path: 'margem_consignavel',
        name: 'margem_consignavel',
        component: () =>
          import('@/views/gestao/margem_consignavel/MargemConsignavel.vue'),
      },
      {
        path: 'servidores/:id',
        component: () => import('@/views/gestao/servidor/ServidorDetalhe.vue'),
        meta: {
          gestaoEntidade: true,
        },
        children: [
          {
            path: '',
            component: () =>
              import('@/views/gestao/consignacoes/ConsignacaoMenu.vue'),
            children: [
              {
                path: 'consignacoes/:idconsig/detalhe',
                name: 'consignacao-detalhe',
                component: () =>
                  import('@/views/gestao/consignacoes/ConsignacaoDetalhe.vue'),
              },
              {
                path: 'consignacoes/:idconsig/parcelas',
                name: 'consignacao-parcelas',
                component: () =>
                  import('@/views/gestao/consignacoes/ConsignacaoParcelas.vue'),
              },
              {
                path: 'consignacoes/:idconsig/comprovantes',
                name: 'consignacao-comprovantes',
                component: () =>
                  import(
                    '@/views/gestao/consignacoes/ConsignacaoComprovantes.vue'
                  ),
              },
              {
                path: 'consignacoes/:idconsig/historico',
                name: 'consignacao-historico',
                component: () =>
                  import(
                    '@/views/gestao/consignacoes/ConsignacaoHistorico.vue'
                  ),
              },
              {
                path: 'cancelar-consignacao/:idconsig',
                name: 'cancelar-consignacao',
                component: () =>
                  import('@/views/gestao/consignacoes/ConsignacaoCancelar.vue'),
              },
            ],
          },
          {
            path: '',
            component: () =>
              import('@/views/gestao/reservaCartao/ReservaCartaoMenu.vue'),
            children: [
              {
                path: 'reserva_cartao_cancelar/:idCartao',
                name: 'reserva_cartao_cancelar',
                component: () =>
                  import(
                    '@/views/gestao/reservaCartao/ReservaCartaoCancelar.vue'
                  ),
              },
              {
                path: 'reserva_cartao_detalhe/:idCartao',
                name: 'reserva_cartao_detalhe',
                component: () =>
                  import(
                    '@/views/gestao/reservaCartao/ReservaCartaoDetalhe.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'servidores/:id',
        meta: {
          gestaoEntidade: true,
        },
        component: () => import('@/views/gestao/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/gestao/renegociacao/RenegociacaoMenu.vue'),
            children: [
              {
                path: 'renegociacoes/:idconsig',
                name: 'renegociacao-consignacao-detalhe',
                component: () =>
                  import('@/views/gestao/renegociacao/RenegociacaoDetalhe.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'servidores/:id',
        meta: {
          gestaoEntidade: true,
        },
        component: () => import('@/views/gestao/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/gestao/compraDivida/CompraDividaMenu.vue'),
            children: [
              {
                path: 'compra-dividas/:idcompradivida',
                name: 'compra-divida-detalhe',
                component: () =>
                  import('@/views/gestao/compraDivida/CompraDividaDetalhe.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'servidores/:id',
        meta: {
          gestaoEntidade: true,
        },
        component: () => import('@/views/gestao/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/gestao/propostas/PropostaMenu.vue'),
            children: [
              {
                path: 'propostas/:idproposta',
                name: 'proposta-detalhe',
                component: () =>
                  import('@/views/gestao/propostas/PropostaDetalhe.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'usuarios',
        name: 'usuarios',
        meta: {
          gestaoEntidade: true,
        },
        component: () => import('@/views/gestao/usuarios/UsuariosIndex.vue'),
      },
      {
        path: 'keycloak-usuarios',
        name: 'keycloak-usuarios',
        component: () =>
          import('@/views/gestao/usuarios/UsuariosKeycloakIndex.vue'),
      },
      {
        path: 'consignatarias-usuarios',
        name: 'usuariosconsignatarias',
        component: () =>
          import('@/views/gestao/usuarios/ConsignatariasIndex.vue'),
      },
      {
        path: 'grupos',
        name: 'grupos',
        component: () => import('@/views/gestao/grupo/GrupoIndex.vue'),
      },
      {
        path: 'vinculos',
        name: 'vinculos',
        component: () => import('@/views/gestao/vinculo/VinculoIndex.vue'),
      },
      {
        path: 'vinculos/novo',
        name: 'vinculo-novo',
        component: () => import('@/views/gestao/vinculo/VinculoForm.vue'),
      },
      {
        path: 'vinculos/:id',
        name: 'vinculo-edit',
        component: () => import('@/views/gestao/vinculo/VinculoForm.vue'),
      },
      {
        path: 'permissoes',
        name: 'permissoes',
        component: () => import('@/views/gestao/permissao/PermissaoIndex.vue'),
      },
      {
        path: 'permissoes/new',
        name: 'permissao-new',
        component: () => import('@/views/gestao/permissao/PermissaoForm.vue'),
      },
      {
        path: 'permissoes/:id',
        name: 'permissao-edit',
        component: () => import('@/views/gestao/permissao/PermissaoForm.vue'),
      },
      {
        path: 'importar-arquivo',
        name: 'importar-arquivo',
        component: () =>
          import('@/views/gestao/arquivo/importacao/ImportacaoArquivo.vue'),
        meta: {
          gestaoEntidade: true,
        },
      },
      {
        path: 'gerar-arquivo-movimento',
        name: 'gerar-arquivo-movimento',
        component: () =>
          import('@/views/gestao/arquivo/movimento/GerarArquivoMovimento.vue'),
        meta: {
          gestaoEntidade: true,
        },
      },
      {
        path: 'relatorio',
        name: 'relatorio',
        component: () => import('@/views/gestao/relatorio/Relatorio.vue'),
        meta: {
          gestaoEntidade: true,
          gestaoFinanceira: true,
        },
      },
      {
        path: 'perfilSeguranca',
        name: 'perfilSeguranca',
        component: () =>
          import('@/views/gestao/perfilSeguranca/PerfilSegurancaList.vue'),
      },
      {
        path: 'grupoSeguranca',
        name: 'grupoSeguranca',
        component: () =>
          import('@/views/gestao/grupoSeguranca/GrupoSegurancaList.vue'),
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/gestao/role/RoleList.vue'),
      },
    ],
  },
]
