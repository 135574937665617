import camelCaseKeys from 'camelcase-keys'
import { chain, get } from 'lodash'
import parseLinkHeader from 'parse-link-header'
import { parse, stringify } from 'query-string'

/**
 * Transforma as keys em camelCase.
 * Se value for um object, aplica deep: true
 *
 * @param  {Object} value
 * @return {Object}
 */
export function camelizeKeys(value) {
  return camelCaseKeys(value, { deep: typeof value === 'object' })
}

/**
 * Converte número de CPF em string formatada
 *
 * @export {Function}
 * @param {Number} [input=0]
 * @returns {String}
 */
export function cpfNumberToString(input = 0) {
  if (!input) return null

  const str = input.toString().replace(/\D/g, '').padStart(11, '0')

  const [base, digit] = [str.substr(0, 9), str.substr(9)]

  let result = base.split('')

  result.splice(3, 0, '.')
  result.splice(7, 0, '.')

  result = result.join('')

  return digit ? `${result}-${digit}` : result
}

/**
 * Converte número de CNPJ em string formatada
 *
 * @export {Function}
 * @param {Number} [input=0]
 * @returns {String}
 */
export function cnpjNumberToString(input = 0) {
  if (!input) return null

  const str = input.toString().replace(/\D/g, '').padStart(14, '0')
  const [base, common, digit] = [
    str.substr(0, 8),
    str.substr(8, 4),
    str.substr(12),
  ]

  let result = base.split('')

  result.splice(2, 0, '.')
  result.splice(6, 0, '.')
  result = result.join('')

  return digit ? `${result}/${common}-${digit}` : result
}

/**
 * Recupera os dados do paginador de response.headers
 *
 * @param  {Object} resp
 * @return {Object}
 */
export function getPaginatorDataFromResponse(resp) {
  const links = parseLinkHeader(resp.headers.link) || {}

  const result = {
    page: Number(get(resp, ['headers', 'x-page'], 1)),
    total: Number(get(resp, ['headers', 'x-total'], 0)),
    perPage: Number(get(resp, ['headers', 'x-per-page'], 25)),
    firstPage: Number(get(links, ['first', 'page'])),
    prevPage: Number(get(links, ['perv', 'page'])),
    nextPage: Number(get(links, ['next', 'page'])),
    lastPage: Number(get(links, ['last', 'page'])),
  }

  return result
}

/**
 * Problem: https://github.com/lodash/lodash/issues/3696#issue-307073978
 * Solution: https://github.com/lodash/lodash/issues/3696#issuecomment-383371294
 * Agrupa uma coleção de objetos por uma determinada key e define a ordenação
 *
 * @param  data   array de objetos
 * @param  attr   attribute do agrupamento
 * @param  order  direção da ordenação (por padrão é desc)
 * @return Object objeto agrupado pela key mantendo ordenação
 */
export function groupAndOrderBy(data, attr, order = 'desc') {
  return chain(data)
    .groupBy((item) => item[attr])
    .map((collection, key) => ({ key, collection }))
    .orderBy((group) => Number(group[attr]), [order])
    .value()
}

/**
 * Converte valor monetário em número
 *
 * @export {Function}
 * @param {String} [str='']
 * @returns {Number}
 */
export function moneyToNumber(str = '') {
  const num = str.replace(/(R|\$|\s|\.)/gi, '').replace(',', '.')
  return Number(num)
}

/**
 * Formata um número para valor monetário, com separação de milhares e decimais
 *
 * @export {Function}
 * @param {Number} [num=0]
 * @param {Boolean} [space=true]
 * @returns {String}
 */
export function numberToMoney(num = 0, decimals = 2) {
  const currency = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    style: 'currency',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
  const result = currency.format(num)

  return result.trim()
}

/**
 * Formata um número com separação de milhares e decimais
 *
 * @export {Function}
 * @param {Number} [num=0]
 * @returns {String}
 */
export function numberFormat(num = 0, decimals = 2) {
  const money = numberToMoney(num, decimals)
  const number = money.replace('R$', '')

  return number.trim()
}

/**
 * Converte um número para inteiro formatado com separação de milhares
 *
 * @export {Function}
 * @param {Number} [num=0]
 * @returns {String}
 */
export function integerFormat(num = 0) {
  return numberFormat(num).split(',')[0]
}

/**
 * Transforma um texto removendo acentos e carateres especiais
 * Substitui os caracteres por equivalentes sem símbolos
 *
 * @export {Function}
 * @param {String} [str='']
 * @returns {String}
 */
export function normalize(str = '') {
  return str ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
}

/**
 * Converte um objeto em query string
 *
 * @export {Function}
 * @param {Object} [obj={}]
 * @returns {String}
 */
export function objToQueryString(obj = {}) {
  const params = stringify(obj, { arrayFormat: 'bracket', skipNull: true })
  return `?${params}`
}

/**
 * Converte query string em um objeto
 *
 * @export {Function}
 * @param {String} [str='']
 * @returns {Object}
 */
export function queryStringToObject(str = '') {
  const params = parse(str, { arrayFormat: 'bracket', skipNull: true })
  return params
}

/**
 * Limita a quantidade de caracteres de uma string
 *
 * @export {Function}
 * @param {String} [text='']
 * @returns {String}
 */
export function reduceTextSize(text, size = 40) {
  if (text && text.length > size) return text.substring(0, size) + '...'
  return text
}

/**
 * Obtém valor de variável de ambiente, tentando capturar de objeto global ou do ambiente da aplicação como fallback
 *
 * @export {Function}
 * @param {String} name
 * @returns {String}
 */
export function getEnv(name, fallback) {
  return get(window, ['config', name], process.env[name] || fallback)
}
