import login from './login'
import logout from './logout'
import errors from './errors'
import application from './application'

// const root = {
//   path: '/',
//   redirect: '/servidor',
// }

export default [...login, ...errors, ...logout, ...application]
