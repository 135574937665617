export default [
    {
      props: true,
      name: 'error',
      path: '/error/:code',
      component: () => import('@/views/servidor/errors.vue'),
      meta: {
        body: 'login',
      },
    },
  
    {
      path: '*',
      redirect: '/error/404',
    },
  ]
  