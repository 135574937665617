import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'

import auth from '@/modules/auth'

import Config from '@/config'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: routes,
  mode: 'history',
  scrollBehavior: (to, from) => to.path !== from.path && { x: 0, y: 0 },
})

const DEFAULT_TITLE = `${Config.NOME_SISTEMA} - Gestão`

// Guards
router.beforeEach((to, from, next) => {
  // Sempre que houver um 401 devemos forçar o expire nos dados de autenticação
  // para que o usuário seja forçado a refazer o login
  if (/error\/401/.test(from.path)) auth.expire()

  // const logged = Vue.$keycloak.authenticated
  const logged =
    auth.isAuthenticated() &&
    (auth.hasRoleGestao() ||
      auth.hasRoleGestaoEntidade() ||
      auth.hasRoleGestaoFinanceira())
  // const logged = true

  // Redirencionar para definir uma senha.
  // if (logged && /login\/password_confirmation/.test(to.path)) {
  //   auth.expire()
  //   return next(router.history.pending.fullPath)
  // }

  // Verificar autenticação e redirecionar para login se necessário
  const authRequired = to.matched.some((route) => route.meta && route.meta.auth)
  if (!logged && authRequired) return next('/login')

  if (
    (!auth.hasRoleGestaoEntidade() && !auth.hasRoleGestaoFinanceira()) ||
    auth.hasRoleGestao()
  ) {
    return next()
  }

  const authGestaoEntidade = to.matched.some(
    (route) =>
      route.meta && route.meta.gestaoEntidade && auth.hasRoleGestaoEntidade(),
  )

  const authGestaoFinanceira = to.matched.some(
    (route) =>
      route.meta &&
      route.meta.gestaoFinanceira &&
      auth.hasRoleGestaoFinanceira(),
  )
  if (!authGestaoEntidade && !authGestaoFinanceira && to.path !== '/') {
    return next('/')
  }

  return next()
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })

  // Aplica classe predefinida para o body
  const styles = to.matched
    .filter((route) => route.meta)
    .filter((route) => route.meta.body)

  document.body.className = styles.length ? styles.pop().meta.body : ''
})

export default router
