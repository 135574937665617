import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { merge } from 'lodash'
import storage from '../modules/storage'

Vue.use(VueI18n)

Vue.use({
  install(vue) {
    Object.assign(vue.prototype, {
      $ts(path, params) {
        const scope = this.i18nScope
        return this.$t(`${scope}.${path}`, params)
      },
    })
  },
})

export default function i18n({ locale, locales = [] }) {
  const messages = merge(...locales)

  storage.set('locale', locale)

  const i18n = new VueI18n({ locale, messages })

  /**
   * Método para tratamento das mensagens de erros de validação em submissão de formulários
   *
   * @param {*} data Objeto recebido com as validações
   * @param {*} scope Escopo base das mensagens nos arquivos de tradução
   * @param {*} sub Chave para recursão interna em objetos aninhados
   * @returns {Object} Um objeto mapeando as mensagens de erros
   */
  i18n.errors = function errors(data, scope, sub) {
    return Object.keys(data).reduce((result, key) => {
      const error = data[key]
      const path = sub ? `${sub}.${key}` : key

      if (typeof error === 'object')
        return Object.assign(result, { [key]: errors(error, scope, path) })

      const locale = `${scope}.errors.${path}.${error}`
      const resolve = locale.replace(/\.\d+/g, '')
      const message = i18n.t(resolve)

      return Object.assign(result, { [key]: message })
    }, {})
  }

  return i18n
}
