import Vue from 'vue'

/**
 * Seleciona e retorna o elemento HTML indicado em target.
 * O argumento pode ser um seletor CSS válido ou o próprio objeto HTML já referenciado.
 * @param {String | HTMLElement} target
 */
export function select(target) {
  const selector = typeof target === 'string'
  const element = selector ? document.querySelector(target) : target

  return element
}

/**
 * Executa uma roalgem suavizada nativa para o elemento HTML indicado em target.
 * O argumento pode ser um seletor CSS válido ou o próprio objeto HTML já referenciado.
 * @param {String | HTMLElement} target
 */
export async function scrollTo(target) {
  const element = select(target)

  await Vue.nextTick()

  return element && element.scrollIntoView({ behavior: 'smooth' })
}

/**
 * Instancia um IntersectionObserver para o elemento HTML indicado em target com o callback event associado.
 * Retorna um objeto que deve ser guardado em uma referência para acessar seus métodos.
 * O argumento pode ser um seletor CSS válido ou o próprio objeto HTML já referenciado.
 * @param {String | HTMLElement} target
 */
export function observer(target, event) {
  const element = select(target)
  const observer = {
    monitor: undefined,
    init() {
      this.monitor = new IntersectionObserver(([entry]) => event(entry))
      this.monitor.observe(element)
    },
    destroy() {
      this.monitor.disconnect()
      this.monitor = undefined
    },
  }

  return observer
}
