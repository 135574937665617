export default [
  {
    props: true,
    name: 'error',
    path: '/error/:code',
    component: () => import('@/views/gestao/erro/ErrorView.vue'),
    meta: {
      body: 'login',
    },
  },

  {
    path: '*',
    redirect: '/error/404',
  },
]
