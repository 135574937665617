import axios from 'axios'
import auth from './auth'
import events from './events'
import storage from './storage'
import Config from '@/config'

import routerGestao from '@/router/gestao'
import routerServidor from '@/router/servidor'
import routerConsignataria from '@/router/consignataria'

// redirects para erros
const redirects = [401, 403, 404]

function errorRedirect(status) {
  const host = window.location.host
  const parts = host.split('.')

  let path = null
  let router = null
  router = routerServidor
  path = { path } = routerServidor.history.current
  if (parts[0] == 'gestao' || parts[1] == 'gestao') {
    router = routerGestao
    path = { path } = routerGestao.history.current
  }
  if (parts[0] == 'consignataria' || parts[1] == 'consignataria') {
    router = routerConsignataria
    path = { path } = routerConsignataria.history.current
  }

  const redirect = redirects.includes(status)
  const login = path.path.includes('token')
  if (redirect && !login) router.push(`/error/${status}`)
}

// instância do axios
const http = axios.create({
  baseURL: Config.API_BASE_URL,
})

// Dizendo que somos XHR!
http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
http.defaults.headers.common['Content-Type'] =
  'application/x-www-form-urlencoded'
// http.defaults.headers.common['Content-Type'] ='application/json'
// http.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// http.defaults.headers.common['Access-Control-Allow-Headers'] = 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
http.defaults.headers.common.Authorization = `Basic ${auth.clientCredentials}`

http.interceptors.request.use(
  // Antes de enviar a requisição, queremos:
  //   - enviar token CSRF
  //   - ativar estado de "carregamento" da app
  (config) => {
    // Enviando header de locale para i18n
    config.headers['Accept-Language'] = storage.get('locale')

    // Enviando headers de autenticação
    if (auth.token) config.headers.Authorization = `Bearer ${auth.token}`

    events.loadingStart()

    return config
  },

  // Quando houver erro no envio de requisição, queremos:
  //   - desativar estado de "carregamento" da app
  (error) => {
    events.loadingStop()
    // data.count--

    // TODO integrar com módulo de notificações e/ou router!
    // Use `error.response.status` para verificar:
    // - 400 - bad_request - falha em preenchimento de dados
    // - 401 - unauthorized (não autenticado)
    // - 403 - forbidden (não tem permissão)
    // - 404 - not found
    // - 422 - falha de verificação CSRF e CORS
    // - 500 - internal server error

    errorRedirect(error.request.status)

    return Promise.reject(error)
  },
)

http.interceptors.response.use(
  // Quando uma requisição for bem sucedida obtendo uma resposta, queremos:
  //   - desativar estado de "carregamento" da app
  (response) => {
    events.loadingStop()
    return response
  },

  // Quando houver erro no processamento de uma resposta, queremos:
  //   - desativar estado de "carregamento" da app
  (error) => {
    events.loadingStop()
    if (error.response) errorRedirect(error.response.status)
    return Promise.reject(error)
  },
)

export default http
