export default [
  {
    path: '/login',
    component: () => import('@/components/shared/login/index'),
    meta: {
      body: 'bg-light',
    },
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('@/components/shared/login/login'),
      },
      {
        path: 'password_confirmation',
        name: 'password-confirmation',
        component: () =>
          import('@/components/shared/login/password-confirmation'),
      },
      {
        path: 'password-forgot',
        name: 'password-forgot',
        component: () => import('@/components/shared/login/password-forgot'),
      },
    ],
  },
]
