export default [
  {
    path: '/',
    component: () => import('@/views/servidor/App.vue'),
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'matriculas',
        component: () => import('@/views/servidor/servidor/ServidorIndex.vue'),
      },
      {
        path: 'matriculas/:id',
        component: () =>
          import('@/views/servidor/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/servidor/servidor/ServidorMenu.vue'),
            children: [
              {
                path: '',
                name: 'margem',
                component: () =>
                  import('@/views/servidor/servidor/ServidorMargem.vue'),
              },
              {
                path: 'consignacoes',
                name: 'consignacoes',
                component: () =>
                  import('@/views/servidor/consignacoes/ConsignacaoList.vue'),
              },
              {
                path: 'renegociacoes',
                name: 'renegociacoes',
                component: () =>
                  import('@/views/servidor/renegociacao/RenegociacaoList.vue'),
              },
              {
                path: 'reserva_cartao',
                name: 'reserva_cartao',
                component: () =>
                  import(
                    '@/views/servidor/reservaCartao/ReservaCartaoList.vue'
                  ),
              },
              {
                path: 'historico_margem',
                name: 'historico_margem',
                component: () =>
                  import('@/views/servidor/servidor/historicoMargem.vue'),
              },
              {
                path: 'propostas',
                name: 'propostas',
                component: () =>
                  import('@/views/servidor/propostas/PropostaList.vue'),
              },
              {
                path: 'compra-dividas',
                name: 'compra-divida-lista',
                component: () =>
                  import('@/views/servidor/compraDivida/CompraDividaList.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'servidores/:id',
        component: () =>
          import('@/views/servidor/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/servidor/consignacoes/ConsignacaoMenu.vue'),
            children: [
              {
                path: 'consignacoes/:idconsig',
                name: 'consignacao-detalhe',
                component: () =>
                  import(
                    '@/views/servidor/consignacoes/ConsignacaoDetalhe.vue'
                  ),
              },
              {
                path: 'consignacoes/:idconsig/parcelas',
                name: 'consignacao-parcelas',
                component: () =>
                  import(
                    '@/views/servidor/consignacoes/ConsignacaoParcelas.vue'
                  ),
              },
              // {
              //   path: 'consignacoes/:idconsig/comprovantes',
              //   name: 'consignacao-comprovantes',
              //   component: () =>
              //     import(
              //       '@/views/servidor/consignacoes/ConsignacaoComprovantes.vue'
              //     ),
              // },
              // {
              //   path: 'consignacoes/:idconsig/historico',
              //   name: 'consignacao-historico',
              //   component: () =>
              //     import(
              //       '@/views/servidor/consignacoes/ConsignacaoHistorico.vue'
              //     ),
              // },
            ],
          },
        ],
      },
      {
        path: 'servidores/:id',
        component: () =>
          import('@/views/servidor/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/servidor/renegociacao/RenegociacaoMenu.vue'),
            children: [
              {
                path: 'renegociacoes/:idconsig',
                name: 'renegociacao-consignacao-detalhe',
                component: () =>
                  import(
                    '@/views/servidor/renegociacao/RenegociacaoDetalhe.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'servidores/:id',
        component: () =>
          import('@/views/servidor/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/servidor/compraDivida/CompraDividaMenu.vue'),
            children: [
              {
                path: 'compra-dividas/:idcompradivida',
                name: 'compra-divida-detalhe',
                component: () =>
                  import(
                    '@/views/servidor/compraDivida/CompraDividaDetalhe.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'servidores/:id',
        component: () =>
          import('@/views/servidor/servidor/ServidorDetalhe.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('@/views/servidor/propostas/PropostaMenu.vue'),
            children: [
              {
                path: 'propostas/:idproposta',
                name: 'proposta-detalhe',
                component: () =>
                  import('@/views/servidor/propostas/PropostaDetalhe.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/servidor/dashboard.vue'),
      },
      {
        path: '/consentimento',
        name: 'consentimento',
        component: () =>
          import('@/views/servidor/consentimento/ServidorConsentimento.vue'),
      },
      {
        path: 'relatorio',
        name: 'relatorio',
        component: () => import('@/views/servidor/relatorio/Relatorio.vue'),
      },
      {
        path: 'simulador',
        name: 'simulador',
        component: () =>
          import(
            '@/views/servidor/simulador/SimuladorEmprestimoConsignado.vue'
          ),
      },
      {
        path: 'matricula/:matricula/contracheque/:cpf',
        name: 'contrachequeMenu',
        component: () =>
          import('@/views/servidor/contracheque/ContrachequeMenu.vue'),
        children: [
          {
            path: '/',
            name: 'contrachequeList',
            component: () =>
              import('@/views/servidor/contracheque/ContrachequeIndex.vue'),
          },
          {
            path: 'rendimentos',
            name: 'rendimentos',
            component: () =>
              import('@/views/servidor/contracheque/RendimentosList.vue'),
          },
        ],
      },
      {
        path: '/detalhes/:id/matricula/:matricula/cpf/:cpf',
        name: 'contrachequeDetalhes',
        component: () =>
          import('@/views/servidor/contracheque/ContrachequeDetalhe.vue'),
      },
      {
        path: '/autorizacaoPortabilidade',
        name: 'autorizacaoPortabilidade',
        component: () =>
          import(
            '@/views/servidor/autorizacaoPortabilidade/AutorizacaoPortabilidadeList.vue'
          ),
      },
    ],
  },
  {
    path: '/validacaoContracheque',
    component: () =>
      import('@/views/servidor/contracheque/ValidacaoContracheque.vue'),
    props: (route) => ({
      codigo: route.query.codigo,
      cpf: route.query.cpf,
      bruto: route.query.bruto,
    }),
  },
]
