import { uniqueId } from 'lodash'
import Vue from 'vue'

import { getEnv } from './utils'

/**
 * Objeto Vue para uso de eventos globais na aplicação
 */
const events = new Vue({
  data: {
    toasts: [],
    loading: 0,
    session: 0,
    expire: 0,
  },

  watch: {
    session(value) {
      if (!value && this.expire) clearInterval(this.expire)
    },
  },

  methods: {
    /**
     * Inicia e emite evento para loader global
     */
    loadingStart() {
      if (!this.loading) this.$emit('loading:start')

      this.loading++

      this.sessionExpire()
    },

    /**
     * Encerra e emite evento para loader global
     * @param {Boolean} all
     */
    loadingStop(all = false) {
      if (all) {
        this.loading = 0
      } else {
        this.loading--
      }

      if (!this.loading) this.$emit('loading:stop')
    },

    /**
     * Configura um timer para encerrar a sessão do usuário quando inativo
     * @returns {Boolean}
     */
    sessionExpire() {
      if (this.expire) clearInterval(this.expire)

      // Exclui rotas de login, logout e session-expired
      if (/(login|logout|session)/.test(location.pathname)) return false

      // Configura sessão ativa para 1h
      this.session = +getEnv('VUE_APP_SESSION_TIMEOUT', 3600)
      this.expire = setInterval(() => this.session--, 1e3)

      return true
    },

    /**
     * Adiciona um objeto para mensagens de notificação global
     * @param {String} title
     * @param {String} message
     * @param {String} type
     */
    toastMessage(title, message, type) {
      const id = uniqueId('toast-')

      this.toasts.push({
        id,
        title,
        message,
        type,
      })
    },

    /**
     * Dispensa uma mensagem de notificação global pelo seu id
     * @param {String} id
     */
    toastDismiss(id) {
      const index = this.toasts.findIndex((i) => i.id === id)

      if (index >= 0) this.toasts.splice(index, 1)
    },

    /**
     * Limpa a lista de mensagens de notificação global
     */
    toastsClear() {
      this.toasts = []
    },
  },
})

export default events
